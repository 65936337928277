<template>
    <ODataGrid :data-object="dsBudgetTransfers" hideMultiselectColumn hideGridMenu disableFilterRow disableDeleteConfirm 
        newRecordsPosition="bottom" noActiveRows style="max-height:400px; min-height:150px">
        <OColumn field="FromOrgUnit" width="300" :headerName="$t('From OrgUnit')" sortable v-slot:editor="{ modelValue: row }" editable >
            <OrgunitsLookup :bind="sel=>{row.FromOrgUnit_ID = sel.ID; row.FromOrgUnit = sel.Name;}">
                <template #orgunit>
                    <input type="text" v-model="row.FromOrgUnit">
                </template>
            </OrgunitsLookup> 
        </OColumn>
        <OColumn field="ToOrgUnit" :headerName="$t('To OrgUnit')"  width="300" sortable v-slot:editor="{ modelValue: row }" editable>
             <OrgunitsLookup :bind="sel=>{row.ToOrgUnit_ID = sel.ID; row.ToOrgUnit = sel.Name;}">
                <template #orgunit>
                    <input type="text" v-model="row.ToOrgUnit">
                </template>
            </OrgunitsLookup>
        </OColumn>
        <OColumn field="Title" :headerName="$t('Title')" width="230" sortable editable  v-slot:editor="{ modelValue: row }">
            <input type="text" v-model="row.Title">
        </OColumn>
        <OColumn field="Amount"  width="100" sortable editable  v-slot:editor="{ modelValue: row }" class="text-end" format="1 234.00">
            <input type="text" v-model="row.Amount" class="text-end"  >
        </OColumn>
    </ODataGrid>

    <div class="mt-3">
        <h6 class="mb-1"><span id="estimateHeader">{{$t("Requested cost changes between WBS elements within a project")}}</span> 
            <button v-if="stepId" class="btn btn-link btn-sm transferEstimate" title="Transfer to Cost Estimate">Transfer to Cost Estimate</button></h6>
        
        <ODataGrid :data-object="dsChangesEstimates" hideMultiselectColumn hideGridMenu disableFilterRow style="max-height:400px; min-height:150px"
            disableDeleteConfirm newRecordsPosition="bottom" noActiveRows>
            <OColumn field="ID" width="60" :headerName="$t('ID')" sortable></OColumn>
            <OColumn field="FromWBS_ID" :headerName="$t('From WBS_ID')" hide  width="80" sortable editable ></OColumn>
            <OColumn field="FromWBSNameAndTitle" :headerName="$t('From WBS')" width="250" sortable editable v-slot:editor="{modelValue: row}">
                <ODataLookup 
                    :data-object="dsWbsLkp" 
                    v-model="row.FromWBSNameAndTitle"
                    :bind="sel=>{ row.FromWBS_ID=sel.ID;row.FromWBSNameAndTitle = sel.NameAndTitle }">
                    <o-column field="Name" width="120"></o-column>
                    <o-column field="Title" width="200"></o-column>
                    <o-column field="NamePath" width="400"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn field="WBS_ID" hide width="80" sortable editable ></OColumn>
            <OColumn field="WBSNameAndTitle" :headerName="$t('WBS')" width="250" sortable editable v-slot:editor="{modelValue: row}">
                <ODataLookup 
                    :data-object="dsWbsLkp" 
                    v-model="row.WBSNameAndTitle"
                    :bind="sel=>{ row.WBS_ID=sel.ID;row.WBSNameAndTitle = sel.NameAndTitle }">
                    <o-column field="Name" width="120"></o-column>
                    <o-column field="Title" width="200"></o-column>
                    <o-column field="NamePath" width="400"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn field="Name" :headerName="$t('Item')" width="130" sortable editable hide></OColumn>
            <OColumn field="Title" width="130" sortable editable ></OColumn>
            <OColumn field="Unit" width="80" sortable editable v-slot:editor="{modelValue: row}">
                <ODataLookup 
                    :data-object="dsUnitLkp" 
                    v-model="row.Unit"
                    :bind="sel=>{ row.Unit=sel.NameOriginal;row.UnitTranslated = sel.Name }">
                    <o-column field="Name" width="200"></o-column>
                    <o-column field="Description" width="200"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn field="Quantity"  width="60" :headerName="$t('QTY')" sortable editable format="1 234.1234" class="text-end"></OColumn>
            <OColumn field="Rate"  width="60" sortable editable format="1 234.12" class="text-end"></OColumn>
            <OColumn field="VATRate"  width="80" sortable editable format="%" class="text-end"></OColumn>
            <OColumn field="Estimate"  width="100" sortable editable  format="1 234.12" class="text-end"></OColumn>
        </ODataGrid>
    </div>

    <div class="mt-3" v-if="stepId">
        <h6 class="mb-1">{{$t("Related Milestones")}}</h6>
        <ODataGrid :data-object="dsChangesMilestones" class="mt-2" hideMultiselectColumn hideGridMenu disableFilterRow style="max-height:400px; min-height:150px"
            disableDeleteConfirm newRecordsPosition="bottom" noActiveRows>
            <OColumn field="Milestone_ID" width="80" :headerName="$t('ID')" sortable  editable v-slot:editor="{modelValue: row}">
                <ODataLookup 
                    :data-object="dsMilestonesLkp" 
                    v-model="row.WBSNameAndTitle"
                    :bind="sel=>{ row.Milestone_ID=sel.ID;row.Milestone = sel.Name, row.Title = sel.Title }">
                    <o-column field="Name" width="220"></o-column>
                    <o-column field="Title" width="300"></o-column>
                    <o-column field="NamePath" width="200"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn field="Milestone" :headerName="$t('Milestone')"   width="250" sortable ></OColumn>
            <OColumn field="Title" :headerName="$t('Title')" width="300" sortable  ></OColumn>
            <OColumn field="MilestoneDate"  width="150" sortable editable ></OColumn>
        </ODataGrid>
    </div>
   
</template>


<script setup>   
    import { ref, defineProps } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import context from 'o365.modules.Context.ts';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean
    });

    const counter = ref(0);

    const dsChangesEstimates = getOrCreateDataObject({
        id: 'dsChangesEstimates',
        viewName: 'aviw_Cost_ChangesEstimates',
        maxRecords: 1,
        uniqueTable: 'atbv_Cost_ChangesEstimates',
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number", sortOrder:"1", direction:"desc"},
             {name: "Workflow_ID", type: "number" },
             {name: "Name", type: "string" },
             {name: "Title", type: "string" },
             {name: "Quantity", type: "number" },
             {name: "Unit", type: "string" },
             {name: "Rate", type: "number" },
             {name: "VATRate", type: "number" },
             {name: "WBS_ID", type: "number" },
             {name: "EstimateWithoutVAT", type: "number" },
             {name: "VAT", type: "number" },
             {name: "Estimate", type: "number" },
             {name: "Transferred", type: "number" },
             {name: "FromWBS_ID", type: "number" },
             {name: "Baseline_ID", type: "number" },
             {name: "WBSNameAndTitle", type: "string" },
             {name: "FromWBSNameAndTitle", type: "string" },
             {name: "UnitTranslated", type: "string" },
             {name: "WBSClosed", type: "datetime" },
             {name: "FromWBSClosed", type: "datetime" },
             ]
    });


    dsChangesEstimates.recordSource.whereClause = "Workflow_ID =" + props.itemId;
    dsChangesEstimates.load();

    dsChangesEstimates.on("BeforeSave", (pRow)=>{
        pRow.values.Workflow_ID = props.itemId;
    });

    const dsBudgetTransfers = getOrCreateDataObject({
        id: 'dsBudgetTransfers',
        viewName: 'aviw_Cost_BudgetTransfers',
        maxRecords: 50,
        uniqueTable: 'atbv_Cost_BudgetTransfers',
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number", sortOrder:"1", direction:"desc"},
             {name: "Workflow_ID", type: "number" },
             {name: "FromOrgUnit_ID", type: "number" },
             {name: "ToOrgUnit_ID", type: "number" },
             {name: "Amount", type: "number" },
             {name: "Title", type: "string" },
             {name: "Description", type: "string" },
             {name: "FromOrgUnit", type: "string" },
             {name: "ToOrgUnit", type: "string" },
        ]
    });

    dsBudgetTransfers.recordSource.whereClause = "Workflow_ID =" + props.itemId;
    dsBudgetTransfers.load();

    dsBudgetTransfers.on("BeforeSave", (pRow)=>{
        pRow.values.Workflow_ID = props.itemId;
    });


    const dsChangesMilestones = getOrCreateDataObject({
        id: 'dsChangesMilestones',
        viewName: 'aviw_Cost_ChangesMilestones',
        maxRecords: 50,
        uniqueTable: 'atbv_Cost_ChangesMilestones',
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number", },
             {name: "Workflow_ID", type: "number" },
             {name: "Milestone_ID", type: "number" },
             {name: "Milestone", type: "string", sortOrder:"1", direction:"asc"},
             {name: "Title", type: "string" },
             {name: "MilestoneDate", type: "datetime" }
        ]
    });
    
    dsChangesMilestones.recordSource.whereClause = "Workflow_ID =" + props.itemId;
    dsChangesMilestones.load();

    dsChangesMilestones.on("BeforeSave", (pRow)=>{
        pRow.values.Workflow_ID = props.itemId;
    });

    const dsWbsLkp = getOrCreateDataObject({
        id: 'dsWbsLkp',
        viewName: 'aviw_Complete_WBS',
        maxRecords: 50,
        fields:
             [{name: "ID", type: "number", },
             {name: "Name", type: "string" },
             {name: "Title", type: "string" },
             {name: "NamePath", type: "string", sortOrder:"1", direction:"asc"},
             {name: "Level", type: "string" },
             {name: "HasNodes", type: "bool"},
             {name: "NameAndTitle", type: "string"}
        ]
    });

    dsWbsLkp.recordSource.whereClause = "HasNodes=0 AND OrgUnit_ID=" + context.id;

    const dsUnitLkp = getOrCreateDataObject({
        id: 'dsUnitLkp',
        viewName: 'sviw_System_Units',
        maxRecords: 50,
        fields:
             [{name: "ID", type: "number", },
             {name: "Name", type: "string" },
             {name: "Description", type: "string" },
             {name: "NameOriginal", type: "datetime"}
        ],
        whereClause: "Closed IS NULL"
    });

    const dsMilestonesLkp = getOrCreateDataObject({
        id: 'dsMilestonesLkp',
        viewName: 'aviw_Complete_Milestones',
        maxRecords: 50,
        fields:
             [{name: "ID", type: "number", },
             {name: "Name", type: "string" },
             {name: "Title", type: "string" },
             {name: "MilestoneDate", type: "datetime", sortOrder:"1", direction:"AscNullsLast"},
             {name: "Description", type: "string" },
             {name: "OrgUnit", type: "string" },
             {name: "OrgUnitIdPath", type: "string" },
             {name: "NameAndTitle", type: "string" },
        ]
    });

    dsMilestonesLkp.recordSource.whereClause = "(MilestoneDate >= GETUTCDATE() OR MilestoneDate IS NULL) AND OrgUnitIdPath LIKE '" + context.idPath + "%'";


    
   
</script>